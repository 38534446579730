import React from "react";
import logo from "./logo.svg";
import "./App.css";
import Home from "./components/Home";
import About from "./components/About";
import MyGames from "./components/MyGames";
import Contact from "./components/Contact";
import Mwb from "./components/MrWalkersBasement";
import Tmn from "./components/ThirtyMinuteNightmare";
import { Switch, Route, BrowserRouter } from "react-router-dom";
import ReactDOM from "react-dom";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route path="/about" component={About} />
        <Route path="/games" component={MyGames} />
        <Route path="/contact" component={Contact} />
        <Route path="/mrwalkersbasement" component={Mwb} />
        <Route path="/thirtyminutenightmare" component={Tmn} />
      </Switch>
      </BrowserRouter>
    </div>
  );
}

// const rootElement = document.getElementById("root");
// ReactDOM.render(
//   <React.StrictMode>
//     <div
//       style={{
//         height: "100vh",
//         display: "flex",
//         justifyContent: "center",
//         alignItems: "center"
//       }}
//     >
//       <div style={{ width: 300,
//       display: "flex",
//       justifyContent: "center",
//       alignItems: "center" }}>
//         <App />
//       </div>
//     </div>
//   </React.StrictMode>,
//   rootElement
// );

export default App;
