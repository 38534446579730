import React from "react";
import ReactDOM from "react-dom";
import "98.css";
import "../App.css";
import MyGames from "./MyGames";
import { NavLink, Link } from "react-router-dom";


const Contact = () => {
  return (
    <div className="App">
      <div id="progressbar"></div>
      <div id="scrollPath"></div>
      <div id="progressbarTwo"></div>
      <div id="scrollPathTwo"></div>
        <header>
        <h3 className="toptitle" style={{float: "right", paddingRight: "20px", textDecoration: "underline"}}>digitalbathtub</h3>
    <div style={{ width: 600 }} className="window">
      <div className="title-bar">
        <div className="title-bar-text">Welcome to digitalbathtub</div>
        <div className="title-bar-controls">
          <button aria-label="Minimize" />
          <button aria-label="Maximize" />
          <button aria-label="Close" />
        </div>
      </div>

      <div className="window-body">
        <p style={{ textAlign: "center" }}>Horror games are the best!</p>
        <div className="field-row" style={{ justifyContent: "center" }}>
        <NavLink to="/">
          <button>Home</button>
          </NavLink>
          <NavLink to="/about">
          <button>About</button>
          </NavLink>
          <NavLink to="/games">
          <button>Games</button>
          </NavLink>
          <NavLink to="/contact">
          <button>Contact</button>
          </NavLink>
        </div>
      </div>
    </div>
    </header>
    <h1 className="title" style={{paddingTop: "20px"}}>digitalbathtub</h1>
 <h2 style={{paddingTop: "20px"}}>contact</h2>
 <div style={{textAlign: "center"}}><img src="https://90s-tic-tac-toe.netlify.app/globeputar.gif"></img></div>
    <div className="middle">
        <h4 className="undergif" style={{backgroundColor: "black", borderRadius: "10px", padding: "25px", color: "#800040", textAlign: "center"}}>Thank you for your interest in digitalbathtub. If you have any questions or wish to contact us please do so by following the links below!</h4>
        <div className="boxes">
          <a href="https://www.instagram.com/digitalbathtub/">
        <div style={{ textAlign: "center", backgroundColor: "black", borderRadius: "10px black"}} className="box">
            <img src="https://90s-tic-tac-toe.netlify.app/cryclown.gif"></img>
          <p className="desc">Instagram</p>
          </div>
          </a>
        <a href="mailto:forrestdarabian@gmail.com">
        <div style={{ textAlign: "center", backgroundColor: "black", borderRadius: "10px black"}} className="box">
            <img src="https://90s-tic-tac-toe.netlify.app/cryclown.gif"></img>
          <p className="desc">Email</p>
          </div>
          </a>
        </div>
    </div>
    <footer style={{textAlign: "center"}} className="bottom">
      <img className="barb" src="https://90s-tic-tac-toe.netlify.app/boarder_barbwire1.gif"></img>
          <div className="footer-container">
            <div className="copyright">
              <p>
                © 2021
                <a>
                  <b> digitalbathtub </b>
                </a>
                All Rights Reserved.
              </p>
            </div>
            <div className="left">
              Contact / Links
              <li>
                
                <a href="mailto:forrestdarabian@gmail.com">
                  <i className="ion-ios-email fa-icons"></i>
                  Contact Me
                </a>
              </li>
              <li>
                <a href="https://www.forrestdarabian.com/">
                  <i className="fa-angle-double-right"></i>Developers Site
                </a>
              </li>
            </div>
          </div>          <br/>

          <img className="barb" src="https://90s-tic-tac-toe.netlify.app/boarder_barbwire1.gif"></img>
        </footer> <br/>
    </div>
  );
};

export default Contact;
