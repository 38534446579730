import React from "react";
import ReactDOM from "react-dom";
import "98.css";
import "../App.css";
import MyGames from "./MyGames";
import { NavLink, Link } from "react-router-dom";
import header from '../images/1142.png';


const Mwb = () => {
  return (
    <div className="App">
      <div id="progressbar"></div>
      <div id="scrollPath"></div>
      <div id="progressbarTwo"></div>
      <div id="scrollPathTwo"></div>
        <header>
        <h3 className="toptitle" style={{float: "right", paddingRight: "20px", textDecoration: "underline"}}>digitalbathtub</h3>
    <div style={{ width: 600 }} className="window">
      <div className="title-bar">
        <div className="title-bar-text">Welcome to digitalbathtub</div>
        <div className="title-bar-controls">
          <button aria-label="Minimize" />
          <button aria-label="Maximize" />
          <button aria-label="Close" />
        </div>
      </div>

      <div className="window-body">
        <p style={{ textAlign: "center" }}>Horror games are the best!</p>
        <div className="field-row" style={{ justifyContent: "center" }}>
        <NavLink to="/">
          <button>Home</button>
          </NavLink>
          <NavLink to="/about">
          <button>About</button>
          </NavLink>
          <NavLink to="/games">
          <button>Games</button>
          </NavLink>
          <NavLink to="/contact">
          <button>Contact</button>
          </NavLink>
        </div>
      </div>
    </div>
    </header>
    <h1 className="title" style={{paddingTop: "20px"}}>digitalbathtub</h1>
 <h2 style={{paddingTop: "20px"}}>mr. walker's basement</h2>
 <div style={{textAlign: "center"}}><img src="https://90s-tic-tac-toe.netlify.app/monster-hugo.gif"></img></div>
    <div className="middle">
        <h4 className="undergif" style={{backgroundColor: "black", padding: "25px", borderRadius: "10px", color: "#800040", textAlign: "center"}}>You hear a scream coming from your neighbor's house...Upon investigating, you soon realize that this may be the last night of your life. You know your neighbor, Mr. Walker, as an old retired movie prop maker always working on his eerie wax figures down in the basement. It is up to you to escape the horrors that unfold under his home. This atmospheric point-and-click survival horror game with elements of mystery and story-rich scares will leave you DYING to uncover the truth. Will you be able to escape Mr. Walker's Basement?</h4>
        <div style={{ textAlign: "center"}} className="boxes">
        {/* <div style={{ textAlign: "center", backgroundColor: "black", borderRadius: "10px black"}} className="box"> */}
            {/* <img className="wolf" src={header}></img> */}
            <a href="https://store.steampowered.com/app/1443310/Mr_Walkers_Basement/">  <img className="wolf" style={{marginBottom: "50px", borderRadius: "20px", maxWidth: "500px"}} src={header}></img> </a>

          <p style={{paddingLeft: "10px", textAlign: "start"}} className="desc">A First-Person Atmospheric Experience<br/><br/>Classic 90's Point and Click-Style Gameplay<br/><br/>Horrifying Antagonists With a Bloodlust Just For You<br/><br/>Intense and Creepy Ambient Soundtrack<br/><br/>Story-Rich Comic Book Cutscenes</p>
          {/* </div> */}
        {/* <div style={{ textAlign: "center", backgroundColor: "black", borderRadius: "10px black"}} className="box">
            <img src="https://90s-tic-tac-toe.netlify.app/cryclown.gif"></img>
          <p className="desc">Classic 90's Point and Click-Style Gameplay</p>
          </div>
          <div style={{ textAlign: "center", backgroundColor: "black", borderRadius: "10px black"}} className="box">
            <img src="https://90s-tic-tac-toe.netlify.app/cryclown.gif"></img>
          <p className="desc">Horrifying Antagonists With a Bloodlust Just For You
</p>
          </div>
          <div style={{ textAlign: "center", backgroundColor: "black", borderRadius: "10px black"}} className="box">
            <img src="https://90s-tic-tac-toe.netlify.app/cryclown.gif"></img>
          <p className="desc">Intense and Creepy Ambient Soundtrack
</p>
          </div>
          <div style={{ textAlign: "center", backgroundColor: "black", borderRadius: "10px black"}} className="box">
            <img src="https://90s-tic-tac-toe.netlify.app/cryclown.gif"></img>
          <p className="desc">Story-Rich Comic Book Cutscenes
</p>
          </div> */}
        </div>
    </div>
    <h4 className="undergif" style={{backgroundColor: "black", padding: "25px", borderRadius: "10px", color: "#800040", textAlign: "center"}}>Mr. Walker's Basement is available now! Click<a href="https://store.steampowered.com/app/1443310/Mr_Walkers_Basement/"> HERE </a>to purchase on Steam!</h4>

    <footer style={{textAlign: "center"}} className="bottom">
      <img className="barb" src="https://90s-tic-tac-toe.netlify.app/boarder_barbwire1.gif"></img>
          <div className="footer-container">
            <div className="copyright">
              <p>
                © 2021
                <a>
                  <b> digitalbathtub </b>
                </a>
                All Rights Reserved.
              </p>
            </div>
            <div className="left">
              Contact / Links
              <li>
                
                <a href="mailto:forrestdarabian@gmail.com">
                  <i className="ion-ios-email fa-icons"></i>
                  Contact Me
                </a>
              </li>
              <li>
                <a href="https://www.forrestdarabian.com/">
                  <i className="fa-angle-double-right"></i>Developers Site
                </a>
              </li>
            </div>
          </div>          <br/>

          <img className="barb" src="https://90s-tic-tac-toe.netlify.app/boarder_barbwire1.gif"></img>
        </footer> <br/>
    </div>
  );
};

// const rootElement = document.getElementById("root");
// ReactDOM.render(
//   <React.StrictMode>
//     <div
//       style={{
//         height: "100vh",
//         display: "flex",
//         justifyContent: "center",
//         alignItems: "center"
//       }}
//     >
//       <div style={{ width: 300,
//       display: "flex",
//       justifyContent: "center",
//       alignItems: "center" }}>
//         <Home />
//       </div>
//     </div>
//   </React.StrictMode>,
//   rootElement
// );


export default Mwb;
