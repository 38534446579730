import React from "react";
import ReactDOM from "react-dom";
import "98.css";
import "../App.css";
import MyGames from "./MyGames";
import { NavLink, Link } from "react-router-dom";
import header from '../images/Untitled-1-Recoveredert.png';


const Mwb = () => {
  return (
    <div className="App">
      <div id="progressbar"></div>
      <div id="scrollPath"></div>
      <div id="progressbarTwo"></div>
      <div id="scrollPathTwo"></div>
        <header>
        <h3 className="toptitle" style={{float: "right", paddingRight: "20px", textDecoration: "underline"}}>digitalbathtub</h3>
    <div style={{ width: 600 }} className="window">
      <div className="title-bar">
        <div className="title-bar-text">Welcome to digitalbathtub</div>
        <div className="title-bar-controls">
          <button aria-label="Minimize" />
          <button aria-label="Maximize" />
          <button aria-label="Close" />
        </div>
      </div>

      <div className="window-body">
        <p style={{ textAlign: "center" }}>Horror games are the best!</p>
        <div className="field-row" style={{ justifyContent: "center" }}>
        <NavLink to="/">
          <button>Home</button>
          </NavLink>
          <NavLink to="/about">
          <button>About</button>
          </NavLink>
          <NavLink to="/games">
          <button>Games</button>
          </NavLink>
          <NavLink to="/contact">
          <button>Contact</button>
          </NavLink>
        </div>
      </div>
    </div>
    </header>
    <h1 className="title" style={{paddingTop: "20px"}}>digitalbathtub</h1>
 <h2 style={{paddingTop: "20px"}}>thirty minute nightmare</h2>
 <div style={{textAlign: "center"}}><img src="https://90s-tic-tac-toe.netlify.app/monster-hugo.gif"></img></div>
    <div className="middle">
        <h4 className="undergif" style={{backgroundColor: "black", padding: "25px", borderRadius: "10px", color: "#800040", textAlign: "center"}}>A short and sweet Survival Horror story with terrifying environments, a dark-ambient soundtrack and creatures hiding behind every corner. This is both a 90's classic PSX-style experience as well as a horrifying modern day adventure: The era and style of graphics is your choice. Good luck...</h4>
        <div style={{ textAlign: "center"}} className="boxes">
        {/* <div style={{ textAlign: "center", backgroundColor: "black", borderRadius: "10px black"}} className="box"> */}
            {/* <img className="wolf" src={header}></img> */}
          <a href="https://store.steampowered.com/app/1655090/"> <img className="wolf" style={{marginBottom: "50px", borderRadius: "20px", maxWidth: "400px"}} src={header}></img> </a> 

          <p style={{paddingLeft: "10px", textAlign: "start"}} className="desc">A First-Person Survival Horror Experience<br/><br/>Your Choice of Modern or Classic 90's PSX-Style Graphics<br/><br/>Creepy Enemies That Follow Your Every Step<br/><br/>Intense and Eerie Ambient Soundtrack<br/><br/>Short and Sweet Story That Doesn't Overstay It's Welcome</p>
          {/* </div> */}
        {/* <div style={{ textAlign: "center", backgroundColor: "black", borderRadius: "10px black"}} className="box">
            <img src="https://90s-tic-tac-toe.netlify.app/cryclown.gif"></img>
          <p className="desc">Classic 90's Point and Click-Style Gameplay</p>
          </div>
          <div style={{ textAlign: "center", backgroundColor: "black", borderRadius: "10px black"}} className="box">
            <img src="https://90s-tic-tac-toe.netlify.app/cryclown.gif"></img>
          <p className="desc">Horrifying Antagonists With a Bloodlust Just For You
</p>
          </div>
          <div style={{ textAlign: "center", backgroundColor: "black", borderRadius: "10px black"}} className="box">
            <img src="https://90s-tic-tac-toe.netlify.app/cryclown.gif"></img>
          <p className="desc">Intense and Creepy Ambient Soundtrack
</p>
          </div>
          <div style={{ textAlign: "center", backgroundColor: "black", borderRadius: "10px black"}} className="box">
            <img src="https://90s-tic-tac-toe.netlify.app/cryclown.gif"></img>
          <p className="desc">Story-Rich Comic Book Cutscenes
</p>
          </div> */}
        </div>
    </div>
    <h4 className="undergif" style={{backgroundColor: "black", padding: "25px", borderRadius: "10px", color: "#800040", textAlign: "center"}}>Thirty Minute Nightmare is available now! Click<a href="https://store.steampowered.com/app/1655090/"> HERE </a>to purchase on Steam!</h4>

    <footer style={{textAlign: "center"}} className="bottom">
      <img className="barb" src="https://90s-tic-tac-toe.netlify.app/boarder_barbwire1.gif"></img>
          <div className="footer-container">
            <div className="copyright">
              <p>
                © 2021
                <a>
                  <b> digitalbathtub </b>
                </a>
                All Rights Reserved.
              </p>
            </div>
            <div className="left">
              Contact / Links
              <li>
                
                <a href="mailto:forrestdarabian@gmail.com">
                  <i className="ion-ios-email fa-icons"></i>
                  Contact Me
                </a>
              </li>
              <li>
                <a href="https://www.forrestdarabian.com/">
                  <i className="fa-angle-double-right"></i>Developers Site
                </a>
              </li>
            </div>
          </div>          <br/>

          <img className="barb" src="https://90s-tic-tac-toe.netlify.app/boarder_barbwire1.gif"></img>
        </footer> <br/>
    </div>
  );
};

// const rootElement = document.getElementById("root");
// ReactDOM.render(
//   <React.StrictMode>
//     <div
//       style={{
//         height: "100vh",
//         display: "flex",
//         justifyContent: "center",
//         alignItems: "center"
//       }}
//     >
//       <div style={{ width: 300,
//       display: "flex",
//       justifyContent: "center",
//       alignItems: "center" }}>
//         <Home />
//       </div>
//     </div>
//   </React.StrictMode>,
//   rootElement
// );


export default Mwb;
